$(document).ready(function () {
    $('#listItemsShowMore').nextAll().hide();
    $('#logosShowMore').nextAll().hide();

    $("#listItemsShowMore").on("click", function () {
        $(this).find('.btn').toggleClass("active");
        $(this).nextAll().slideToggle();
    });

    $("#logosShowMore").on("click", function () {
        $(this).find('.btn').toggleClass("active");
        $(this).nextAll().slideToggle();
    });

    if (window.innerWidth < 1280 && window.innerWidth > 767) {
        $('.list-items .list-items__item:nth-child(4)').after($('#listItemsShowMore'));
        $('#listItemsShowMore').nextAll().hide();
    } else if (window.innerWidth < 768 && window.innerWidth > 374) {
        $('.list-items .list-items__item:nth-child(3)').after($('#listItemsShowMore'));
        $('#listItemsShowMore').nextAll().hide();
    } else if (window.innerWidth < 375) {
        $('.list-items .list-items__item:nth-child(1)').after($('#listItemsShowMore'));
        $('#listItemsShowMore').nextAll().hide();
    }

    if (window.innerWidth < 1280 && window.innerWidth > 767) {
        $('.logos-list-white .logos-list-white__item:nth-child(4)').after($('#logosShowMore'));
        $('#logosShowMore').nextAll().hide();
    } else if (window.innerWidth < 768 && window.innerWidth > 374) {
        $('.logos-list-white .logos-list-white__item:nth-child(5)').after($('#logosShowMore'));
        $('#logosShowMore').nextAll().hide();
    } else if (window.innerWidth < 375) {
        $('.logos-list-white .logos-list-white__item:nth-child(1)').after($('#logosShowMore'));
        $('#logosShowMore').nextAll().hide();
    }

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        // dots: true,
        centerMode: true,
        focusOnSelect: true
    });

    $('.slick').slick({
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    })
    $('.burger').on('click', function () {
        $(this).toggleClass('active');
        $('body').toggleClass('hidden');
        $('.main-nav').toggleClass('active');
    })
});
